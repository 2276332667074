<template>
    <div>

        <v-container>
            <v-card :elevation="1" class="v-sheet theme--light br-0">
                <div class="row mt-0 mb-4">
                    <div class="col-12 col-md-12 m-0 p-0" style="margin-top: -5px !important;">
                        <DivisorColor  :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`"/>
                    </div>
                </div>
                <v-card-title>

                    <v-row class="col-md-12">
                        <v-col cols="12" sm="12" md="12">
                            <p class="text-h5 font-weight-black mb-0">Búsqueda de proyectos </p>
                            <!--<v-subheader class="pl-0 font-weight-black text-subtitle-1 text--blue-grey text--lighten-4">
                                
                            </v-subheader>-->
                        </v-col>
                    </v-row>

                    <v-row class="col-md-12 m-0 p-0">
                    <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text class="pl-0 pr-0">
                    <v-container class="p-8">
                        <v-form 
                            ref="formDatosFinancieros"
                            v-on:submit.prevent="buscarProyectos"
                            v-model="validFormBusqueda"
                        >
                            <v-row>
                            
                                <v-col cols="12" md="8" sm="12" xs="12" class="pt-0 pb-0">
                                    <v-text-field
                                        :clearable="true"
                                        filled
                                        autocomplete="off"
                                        dense
                                        v-model="nombreProyecto"
                                        :disabled="btnBusquedaLoading"
                                        label="Nombre del proyecto"
                                        :rules="[
                                            maxLength('nombre del proyecto', 100)
                                        ]"
                                        maxlength="100"
                                    >
                                        
                                    </v-text-field>
                                    
                                </v-col>


                                <v-col cols="12" md="4" sm="12" xs="12" class="pt-0 pb-0">
                                    <v-text-field
                                        :clearable="true"
                                        filled
                                        autocomplete="off"
                                        dense
                                        v-model="codigoProyecto"
                                        :disabled="btnBusquedaLoading"
                                        label="Código del proyecto"
                                        :rules="[
                                            maxLength('código del proyecto SICOOPERA', 20)
                                        ]"
                                        maxlength="20"
                                    >
                                        
                                    </v-text-field>
                                    
                                </v-col>
                            </v-row>


                            <!--<v-row>
                            
                                

                                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                                    <v-text-field
                                        :clearable="true"
                                        filled
                                        autocomplete="off"
                                        dense
                                        v-model="codigoProyectoSiced"
                                        label="Ingrese el código del proyecto SICED"
                                        :rules="[
                                            maxLength('código del proyecto SICED', 50)
                                        ]"
                                        maxlength="50"
                                    >
                                        
                                    </v-text-field>
                                    
                                </v-col> 
                            </v-row>-->

                            <v-row>

                                <v-col cols="12" md="8" sm="12" xs="12" class="pt-0 pb-0">
                                    <v-autocomplete
                                        v-model="entidadSeleccionada"
                                        :items="instituciones"
                                        class=""
                                        :clearable="true"
                                        dense
                                        filled
                                        :loading="ddInstitucionesLoading"
                                        :disabled="btnBusquedaLoading"
                                        label="Institución"
                                        :item-text="item => item.sigla ? `${item.nombreInstitucion} (${item.sigla})` : item.nombreInstitucion"
                                        item-value="id"
                                        
                                        
                                        return-object
                                    ></v-autocomplete>
                                </v-col>


                                <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                                    <v-select
                                        :clearable="true"
                                        v-model="fuenteCooperanteSeleccionada"
                                        :items="fuentesCooperantes"
                                        :loading="ddFuentesCooperantesLoading"
                                        :disabled="btnBusquedaLoading"
                                        dense
                                        filled
                                        label="Fuente cooperante"
                                        :no-data-text="'No se encontraron fuentes cooperantes registradas'"
                                        menu-props="auto"
                                        return-object
                                        :item-text="item => item.tipoFuenteCooperante + ' - ' + item.nombre"
                                        item-value="id"
                                    ></v-select>
                                </v-col>


                            
                            
                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                                    <v-btn
                                        class="white--text float-right  mr-1 mb-2"
                                        x-large
                                        color="light-blue-502"
                                        :loading="btnBusquedaLoading"
                                        :disabled="!validFormBusqueda || btnBusquedaLoading"
                                        type="submit"
                                    >
                                        <v-icon left>mdi-magnify</v-icon> Buscar
                                    </v-btn>

                                    <v-btn
                                        color="grey lighten-5"
                                        elevation="0"
                                        class="mb-2 float-right grey lighten-4 mr-2"
                                        :loading="false"
                                        x-large
                                        :disabled="btnBusquedaLoading"
                                        @click="limpiarFiltros();"
                                        >
                                        <v-icon>mdi-trash</v-icon>
                                        Limpiar
                                </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>

                    <v-data-table 
                        class="elevation-0 mt-12"
                        :headers="headers"
                        :items="proyectos"
                        :search="filtro"
                        :loading="tableLoading"
                        loading-text="Actualizando información..."
                        :header-props="{
                            sortByText: 'Ordenar por'
                        }"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            'items-per-page-text': 'Registros por página',
                            pageText: '{0}-{1} de {2}'
                        }"
                        no-data-text="No se han encontrado proyectos"
                        >
                        <!-- <template v-slot:top>
                        
                            <v-container fluid>
                            
                            </v-container>
                        </template> -->

                        <template v-slot:item="{ item }" class="mt-4">
                            <tr>
                                <td>{{ item.codigo }}</td>
                                <td>{{ item.nombreproyecto }}</td>
                                <td>{{ item.fuentecooperante }}</td>

                                <td>{{ item.entidad }}</td>
                                <td>{{ item.estado }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-container>

            <!--dialog mapa ubicaciones-->
            <v-dialog v-model="dialogUbicaciones"  transition="scroll-y-transition">
            <v-card>
                <v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    :disabled="false"
                    @click="dialogUbicaciones = false; resetUbicaciones()"
                    class="float-right"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-card-title>
                <v-card-text style="letter-spacing: 0.1px !important">
                <GmapMap
                    :center="{ lat: 16.062775, lng: -89.3520961 }"
                    :zoom="8"
                    ref="map"
                    id="map"
                    style="width: 100%; height: 800px"
                >
                    <GmapMarker
                        v-for="(m, index) in marcadores"
                        :key="index"
                        :position="m.position"
                        :clickable="true"
                        :draggable="false"
                        @click="
                            center = m.position;
                            openGmapInfo(index);
                        "
                    >
                    <gmap-info-window
                        :position="infoWindow.position"
                        :opened="infoWindow.open[index]"
                        @closeclick="closeInfoWindow(index)"
                    >
                        <!--<button @click="closeInfoWindow(index)">Close</button>-->
                        <div v-html="infoWindow.template">
                        </div>
                        
                    </gmap-info-window> 
                    </GmapMarker>
                </GmapMap>
                </v-card-text>
            
            </v-card>
            
        </v-dialog>
    </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";
import {OBTENER_ITEMS_CATALOGO} from "@/core/services/store/catalogos/catalogointerno.module";
import { REPORTE_PROYECTOS_CNS_SICED } from "@/core/services/store/proyectoscns/proyectocns.module";
import validations from "@/core/untils/validations.js";
export default {
    name: "ReporteGeneralProyectos",
    components: {
        DivisorColor
    },
    data() {
        return{ 
            busqueda: false,
            nombreProyecto: "",
            codigoProyecto: "",
            filtros: {
                entidadId: 0,
                texto: "",
                codigo: "",
                fuenteCooperanteScoId: 0,
                fuenteCooperanteSiced: "",
                tipoFuenteCooperante: "",                
            },
            fuenteCooperanteSeleccionada:null,
            fuentesCooperantes: [],
            proyectos: [],
            ddFuentesCooperantesLoading: false,
            entidadSeleccionada: null,
            instituciones: [],
            ddInstitucionesLoading: false,
            filtro: "",
            validFormBusqueda:false,
            btnBusquedaLoading: false,
            estadoProyecto: [],
            dialogUbicaciones: false,
            tableLoading: false,
            opcionBusquedaSeleccionada:{},
            opcionesBusqueda: [{id: 1, text: "Nombre del proyecto"}, {id: 2, text: "Código SIGEACI"}/*, {id: 3, text: "Fuente cooperante"}, {id: 4, text: "Nombre de institución"}, {id: 5, text: "Fecha de suscripción"}, {id: 6, text: "Fecha de inicio"}*/],
            documentoBusquedaSeleccionado: {},
            documentosBusqueda: [{id: 1, text: "IAFF"}, {id: 2, text: "Resumen ejecutivo IAFF"}, {id: 3, text: "Convenios"}, {id: 4, text: "Dictamen técnico"}, {id: 5, text: "Autorización cierre"}, {id: 6, text: "Autorización suspensión"}, {id: 7, text: "Autorización finalización"}, {id: 8, text: "Enmiendas"}],
            anio: "",
            periodos: [],
            ddPeriodosLoading: false,
            bimestreSeleccionado: {},
            bimestres: [{id: 1, text: "Primero"}, {id: 2, text: "Segundo"}, {id: 3, text: "Tercero"}, {id: 4, text: "Cuarto"}, {id: 5, text: "Quinto"}, {id: 6, text: "Sexto"}],
            datosConsulta: {
                opcionBusqueda: 0,
                tipoDocumentoId:0,
                texto: "",
                periodosId: 0,
                bimestreId: 0,
                
            },
            marcadores:[],
            infoWindow: {
                open: [],
                position: { lat: 0, lng: 0 },
                template: "",
            },
            ...validations
        }
    },

    methods: {

        //Obtener tipos de datos financieros
        async obtenerFuentesCooperantes(){

            this.fuentesCooperantes = [];

            this.ddFuentesCooperantesLoading=true;
            
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'FuentesCooperantes/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.fuentesCooperantes = res.data;

                }
                this.ddFuentesCooperantesLoading=false;
            })
            .catch(() => {
                this.fuentesCooperantes = [];
                this.ddFuentesCooperantesLoading=false;
            });

        },


        //Obtener entidades o instituciones
        async obtenerEntidades() {
            this.instituciones = [];
            this.ddInstitucionesLoading = true;
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `Entidades/all/1`})
            .then(res => {
            if (res.status === 200) {
                this.instituciones = res.data;
            }
            this.ddInstitucionesLoading = false;
            })
            .catch(() => {
                this.instituciones = [];
                this.ddInstitucionesLoading = false;
            });
        },

        async buscarProyectos(){
            
            this.btnBusquedaLoading=true;
            this.tableLoading=true;
            this.proyectos = [];


            
            if(this.nombreProyecto == null){
                this.filtros.texto = "";
            } else{
                this.filtros.texto = this.nombreProyecto;
            }


            if(this.codigoProyecto == null){
                this.filtros.codigo = "";
            } else{
                this.filtros.codigo = this.codigoProyecto;
            }

            if(!this.entidadSeleccionada){
                this.filtros.entidadId = 0;
            } else {
                this.filtros.entidadId = this.entidadSeleccionada.id;
            }
            
            //Verificar si la fuente es bilateral
            if(!this.fuenteCooperanteSeleccionada){
                this.filtros.fuenteCooperanteScoId=0;
                this.filtros.fuenteCooperanteSiced="";
                //console.log("here")
            } else {
                if(this.fuenteCooperanteSeleccionada.tipoFuenteCooperante === "B"){
                    this.filtros.fuenteCooperanteScoId = this.fuenteCooperanteSeleccionada.idFuenteBi;
                    this.filtros.tipoFuenteCooperante = "B";
                
                }

                if(this.fuenteCooperanteSeleccionada.tipoFuenteCooperante === "M"){
                    this.filtros.fuenteCooperanteScoId = this.fuenteCooperanteSeleccionada.idFuenteMulti;
                    this.filtros.tipoFuenteCooperante = "M";
                }

                this.filtros.fuenteCooperanteSiced = this.fuenteCooperanteSeleccionada.nombre;  
               
            }


            //this.ddPeriodosLoading=true;
            
            await this.$store
            .dispatch(REPORTE_PROYECTOS_CNS_SICED, this.filtros)
            .then(res => {
                if (res.status === 200) {
                    this.proyectos = res.data;
                    this.busqueda = true;
                } else {
                    this.busqueda = false;

                }
                this.tableLoading=false;
                this.btnBusquedaLoading=false;
            })
            .catch(() => {
                this.proyectos = [];
                this.btnBusquedaLoading=false;
                this.tableLoading=false;
            });

        },


        limpiarFiltros(){
            this.fuenteCooperanteSeleccionada = null;
            this.entidadSeleccionada = null;
            this.codigoProyecto = "";
            this.nombreProyecto = "";
            this.filtros= {
                entidadId: 0,
                texto: "",
                codigo: "",
                fuenteCooperanteScoId: 0,
                fuenteCooperanteSiced: "",
                tipoFuenteCooperante: "",                
            };
        },


        //Mostrar el mapa con el/los puntos del proyecto
        mostrarMapa(marcadores){
            //console.log(marcadores)
            
                 //Rellenar los marcadores con lat y long
            marcadores.forEach((element, index) => {
                //console.log(element)
                if(element.latitud && element.longitud) {
                    this.marcadores.push({position: {lat: parseFloat(element.latitud), lng: parseFloat(element.longitud), monto: parseFloat(element.monto)}});
                }    

                this.infoWindow.open[index] = false;
            });


            

           //console.log(this.marcadores)

            this.dialogUbicaciones=true;
        },


        openGmapInfo(index) {
            //console.log(this.infoWindow.open)

           if(this.infoWindow.open && this.infoWindow.open.length > 0){
            //console.log("tiene datos" + this.infoWindow.open);

            this.infoWindow.open.forEach((element, index) => {
                this.infoWindow.open[index] = false;
            });

            //console.log("Ya se borraron los datos" + this.infoWindow.open)

           }

            const {
                lat,
                lng,
                monto
            } = this.marcadores[index].position;

            this.infoWindow.position = { lat: lat, lng: lng };

            this.infoWindow.template = `
            <div class="container" style="letter-spacing: 0.1px; width:100%;">
                <div class="row">
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                        <b>Monto:</b>
                    </div> <!--end:: col-->
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                        GTQ ${parseFloat(monto).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}
                    </div> <!--end:: col-->
                </div> <!--end:: row-->
            </div> <!-- end:: container-->`;

            this.infoWindow.open[index] = true;
            //console.log(this.infoWindow.open);

            //console.log(this.infoWindow)

        } ,

        closeInfoWindow(index){
            //console.log(index);
            this.infoWindow.open[index] = false;
            //console.log(this.infoWindow)
        },

        resetUbicaciones(){
            this.marcadores=[];
            this.infoWindow= {
                open: [],
                position: { lat: 0, lng: 0 },
                template: "",
            };
        }

    },

    created() {
        this.obtenerEntidades();
        this.obtenerFuentesCooperantes();
    },

    computed: {
        headers() { 
            return [
                {
                    text: "Código",
                    align: "start",
                    sortable: false,
                    value: "codigo"
                },
                {
                    text: "Nombre Proyecto",
                    align: "start",
                    sortable: true,
                    value: "nombreProyecto"
                },
                {
                    text: "Fuente cooperante",
                    align: "start",
                    sortable: true,
                    value: "fuenteCooperante"
                },
                {
                    text: "Institución beneficiaria",
                    align: "start",
                    sortable: true,
                    value: "entidad"
                },
                {
                    text: "Estado del proyecto",
                    align: "start",
                    sortable: false,
                    value: "estado"
                }
            ]
        }
    },
     mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Inicio", route: "/" },
            { title: "Consultas" },
            { title: "Proyectos" }
        ]);

        // var ciphertext = this.$CryptoJS.AES.encrypt("2", 'KEYADMINCNS2022');
    }
    
}
</script>
